import Link from 'next/link';
import React from 'react';

const LegacyBanner = () => {
  return (
    <div className="w-full flex text-center container mx-auto relative pt-2">
      <div className="flex-1">
        <span className="mr-2 text-xs">Prefer the old site?</span>
        <Link href={`https://v1.${process.env.LEGACY_DOMAIN}`} className="text-center text-red text-xs">
          Launch →
        </Link>
      </div>
    </div>
  );
};

export default React.memo(LegacyBanner);
