import React from "react"
import LegacyBanner from "./LegacyBanner"
import Logo from "../../global/Logo/Logo"
import AccountMenu from "../../global/Menus/AccountMenu/AccountMenu"
import MainMenu from "../../global/Menus/MainMenu/MainMenu"

const Header = (props: { user?: boolean }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div
      className={`w-full ${
        isOpen ? "h-full" : ""
      } fixed left-0 right-0 top-0 z-50 bg-white`}
    >
      <LegacyBanner />
      <div className="flex items-center container mx-auto pt-0 p-4 relative">
        <Logo />
        <div className="flex-auto flex lg:block items-center justify-end space-x-2 md:space-x-4">
          <AccountMenu user={props.user} />
          <MainMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </div>
  )
}

export default Header
