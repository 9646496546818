import { NextRouter } from "next/router";

export const removeHTML = (string: string) => {
	return string.replace(/<\/?[^>]+(>|$)/g, "");
}

export const createMarkup = (string: string) => {
	return { __html: string };
}

export const getParam = (routerPath: string, key: string) => {
  let queryString = routerPath;
  if (routerPath.match(/\?/)) queryString = queryString.split(/\?/)[1]

  const params = new Proxy(new URLSearchParams(queryString), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  });


  const val = params[key];


  return val ? decodeURIComponent(val) : "";
}

export const parseUSA = (string: string) => {
  if (string === "usa") {
    return "USA";
  } else {
    return string;
  }
}

export const formatTitle = (title: string) => {
  return `${title} | Vinous - Explore All Things Wine`;
}
