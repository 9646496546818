import Link from 'next/link';
import React from 'react';
import IconButton from '../../Buttons/IconButton';
import MenuItem from './MenuItem/MenuItem';

const MainMenu = ({ isOpen, setIsOpen }) => {
	const menuItemClass = "font-extralight text-2xl lg:text-lg xl:text-xl 2xl:text-2xl";
	const subItemClass = "font-extralight text-xl lg:text-base hover:text-red";

	const toggleMenu = (e) => {
		setIsOpen(!isOpen);
	}

	return (
		<nav className="flex flex-col justify-end items-end">
			<IconButton className="lg:hidden" onClick={toggleMenu}>
				{
					!isOpen
					? <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					: <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
						</svg>
				}
			</IconButton>

			<ul className={`space-y-2 2xl:space-x-6 bg-white p-8 pt-0 w-full justify-end absolute top-24 left-0 ${ isOpen ? 'flex-col' : 'hidden' } lg:flex lg:p-0 lg:flex lg:relative lg:top-0 lg:space-y-0 lg:space-x-4`}>
				<MenuItem styleName={menuItemClass} path="/" label="Home"></MenuItem>
				<MenuItem styleName={menuItemClass} path="/articles" label="Articles"></MenuItem>
				<MenuItem styleName={menuItemClass} path="/multimedia" label="Videos"></MenuItem>
				<MenuItem styleName={menuItemClass} path="/statics/maps" label="Maps"></MenuItem>
				<MenuItem styleName={menuItemClass} path="https://cellar-watch.com/" label="Cellar Watch"></MenuItem>
				<MenuItem styleName={menuItemClass} path="https://delectable.com/" label="Delectable"></MenuItem>
				<MenuItem styleName={menuItemClass} path="/events" label="Events"></MenuItem>
				<MenuItem styleName={menuItemClass} path="/" label="Tools" hideChildren={!isOpen}>
					<div className={subItemClass}>
						<Link href="/vintages">
							Vintage Chart
						</Link>
					</div>
					<div className={subItemClass}>
						<Link href="/glossary">
							Glossary
						</Link>
					</div>
					<div className={subItemClass}>
						<Link href="/grape_guide">
							Grape Guide
						</Link>
					</div>
				</MenuItem>
				<MenuItem styleName={menuItemClass} path="https://forum.vinous.com/" label="Your Say" newTab={true}></MenuItem>
			</ul>

		</nav>
	);
}

export default MainMenu;
