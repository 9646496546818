import React from "react";

const TextField = (props: {
	className?: string,
	type?: string,
	placeholder?: string,
	value?: string,
	label?: string,
	name?: string
	required?: boolean
	onFocus?: Function,
	onEnter?: Function,
	onChange: Function,
	id?: string,
	disabled?: boolean
}) => {
	const name = props.label ? props.label.replace(/ /g,"_") : "";

	const disabledClass = props.disabled ? "bg-gray-100 disabled:pointer-events-none" : "";
	return (
		<div
			className="flex flex-col w-full"
			onFocus={props.onFocus ? (e) => props.onFocus(e) : () => {}}
		>
			{(
				props.label &&
				<label
					className="text-sm pl-1"
					htmlFor={ name }
				>
					{ props.label }
				</label>
			)}
			<input
				className={`p-3 appearance-none rounded border-gray-200 h-10 text-base hover:border-red focus:border-red hover:shadow-lg focus:outline-none focus:ring-0 ${ props.className } ${disabledClass}`}
				name={ props.name ? props.name : name }
				style={{ transition: "all .15s ease" }}
				type={ props.type || "text" }
				value={ props?.value?.trimStart() || "" }
				required={props.required || false}
				placeholder={ props.placeholder || "" }
				onKeyDown={(e) => {
					if(e.key === "Enter") {
						typeof props.onEnter === "function" ? props.onEnter() : null
					}
        }}
				id={props.id || props.name}
        onChange={(e) => props.onChange(e)}
				disabled={props.disabled}
			/>
		</div>
	)
}

export default TextField;
